<template>
  <section class="page-content">
    <div class="shorting-wrap">
      <span class="big">{{ category.name }} </span>

      <div class="shorting-drop">
        <span class="total">{{ totalProducts }} {{ $t("result") }}</span>

        <div class="wrap">
          <div class="view-type">
            <div
              @click="changeView('grid')"
              class="view-btn"
              :class="{ active: productViewType === 'grid' }"
            >
              <i class="fas fa-th-large"></i>
            </div>
            <div
              @click="changeView('list')"
              class="view-btn"
              :class="{ active: productViewType === 'list' }"
            >
              <i class="fas fa-bars"></i>
            </div>
          </div>

          <b-button
            v-if="checkIfMobile"
            v-b-toggle.filter-sidebar
            class="btn-transperant mob-filter-btn"
            ><i class="fa fa-filter"></i
            ><span class="ml-2">{{ $t("filters") }}</span></b-button
          >
          <b-button
            v-if="checkIfMobile"
            v-b-toggle.filter-sidebar
            class="btn-transperant mob-filter-btn sticky"
            ><i class="fa fa-filter"></i
            ><span class="ml-2">{{ $t("filters") }}</span></b-button
          >

          <CategoryPageContentFilter />
        </div>
      </div>
    </div>

    <div class="content-filter">
      <div class="cst-pagination-bar">
        <div class="pagination-bar-down">
          <div class="left">
            <div class="display-drop">
              <label>{{ $t("view") }}:</label>
              <select :value="prodsPerPage" @change="changePerPage">
                <option
                  v-for="perPageOption in perPageOptions"
                  :key="perPageOption.value"
                  :value="perPageOption.value"
                >
                  {{ perPageOption.label }}
                </option>
              </select>
            </div>
          </div>
          <label class="dispaly-page"
            >Pagina<span>{{ currentPage }}</span
            >van<span>{{ Math.ceil(totalProducts / prodsPerPage) }}</span>
          </label>
        </div>

        <b-pagination
          :total-rows="totalProducts"
          :per-page="prodsPerPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        >
          <template #page="{ page }">
            <b-link class="page-link" :to="goToPage(page)">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>
    </div>

    <FilteredProducts :viewType="productViewType" />

    <div class="content-filter">
      <div class="cst-pagination-bar bottom-side border-0 mb-0 pb-0">
        <div class="pagination-bar-down">
          <div class="left">
            <div class="display-drop">
              <label>{{ $t("view") }}:</label>
              <select :value="prodsPerPage" @change="changePerPage">
                <option
                  v-for="perPageOption in perPageOptions"
                  :key="perPageOption.value"
                  :value="perPageOption.value"
                >
                  {{ perPageOption.label }}
                </option>
              </select>
            </div>
          </div>
          <label class="dispaly-page"
            >Pagina<span>{{ currentPage }}</span
            >van<span>{{ Math.ceil(totalProducts / prodsPerPage) }}</span>
          </label>
        </div>

        <b-pagination
          :total-rows="totalProducts"
          :per-page="prodsPerPage"
          :value="currentPage"
          @change="changePage"
          class="cst-pagination"
          aria-controls="cat-item"
        >
          <template #page="{ page }">
            <b-link class="page-link" :to="goToPage(page)">
              {{ page }}
            </b-link>
          </template>
        </b-pagination>
      </div>
    </div>
  </section>
</template>

<script>
import config from "@config";
import { isMobile, isTablet } from "mobile-device-detect";
import FilteredProducts from "@/base/core/components/products/category-product/FilteredProducts";
import CategoryPageContentFilter from "@/base/core/components/products/category-product/CategoryPageContentFilter";

export default {
  name: "CategoryPageContent",
  components: {
    CategoryPageContentFilter,
    FilteredProducts,
  },
  data() {
    return {
      productViewType: "grid",
      perPageOptions: [
        { value: 12, label: 12 },
        { value: 24, label: 24 },
        { value: 36, label: 36 },
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch("categorySlider/loadCategorySliders", {
      id: this.category.id,
    });
    var sliders = await this.$store.getters[
      "categorySlider/getCategorySliders"
    ];
    var sliderFound = 0;
    var prodsPerPage = this.prodsPerPage;
    if (sliders) {
      sliders.forEach((slider) => {
        if (slider.identifier.includes("category_advertisement_grid")) {
          sliderFound = 1;
          this.perPageOptions = [
            { value: 11, label: 11 },
            { value: 23, label: 23 },
            { value: 35, label: 35 },
          ];
          if (prodsPerPage % 12 == 0) {
            prodsPerPage = parseInt(prodsPerPage) - 1;
          }
          return;
        }
      });
    }
    if (this.prodsPerPage % 12 != 0 && sliderFound == 0) {
      prodsPerPage = parseInt(prodsPerPage) + 1;
    }
    if (this.prodsPerPage != prodsPerPage) {
      this.prodsPerPage = prodsPerPage;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (prodsPerPage != pageSize) query["page-size"] = prodsPerPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });
      this.$store.commit("category/setProductsPerPage", prodsPerPage);
    } else {
      const { page } = this.$route.query;
      const path = "/" + this.category.url_path;
      const query = { ...this.$route.query };
      if (page && this.totalPages < parseInt(page)) {
        delete query["page"];
      }

      const pageSize = config.products?.perPage;
      const perPage = this.$store.getters["category/getProductsPerPage"];
      if (perPage != pageSize) query["page-size"] = perPage;
      this.$router.replace({ path, query });
    }
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    currentPage() {
      return this.$store.getters["category/getCurrentPage"];
    },
    prodsPerPage: {
      get() {
        return this.$store.getters["category/getProductsPerPage"];
      },
      set() {},
    },
    totalProducts() {
      return this.$store.getters["category/getTotalProducts"];
    },
  },
  created() {
    if (isMobile || isTablet) {
      this.productViewType = "list";
    }
  },
  methods: {
    changeView(value) {
      this.productViewType = value;
    },
    changePage(pageNo) {
      this.$store.commit("category/setCurrentPage", pageNo);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (pageNo != 1) query["page"] = pageNo;
      else delete query["page"];

      this.$router.replace({ path, query });
    },
    goToPage(page) {
      const { path } = this.$route;
      const query = { ...this.$route.query };

      if (page != 1) query["page"] = page;
      else delete query["page"];

      return { path, query };
    },
    changePerPage(event) {
      const perPage = +event.target.value;
      const { path } = this.$route;
      const query = { ...this.$route.query };

      const pageSize = config.products?.perPage;

      if (perPage != pageSize) query["page-size"] = perPage;
      else delete query["page-size"];
      delete query["page"];

      this.$router.replace({ path, query });
      this.$store.commit("category/setProductsPerPage", perPage);
    },
    checkIfMobile() {
      return this.isMobile();
    },
    /*
    async setTotalCount(category) {
      const catData = await getProductFiltersByCategoryId(category.id).catch(
        (e) => {
          console.log(e);
          throw e;
        }
      );
      this.totalProducts = catData.total_count;
    },
    */
  },
};
</script>

<style lang="scss" scoped></style>
