<template>
  <div>
    <div
      class="selected-filters d-none d-md-flex flex-wrap align-items-center"
    ></div>
    <div class="select-wrapper">
      <b-form-select
        id="sorting"
        :value="sorting"
        @change="changeSorting"
        :options="selectOptions"
        class="select"
      />
    </div>
  </div>
</template>

<script>
import config from "@config";
export default {
  name: "CategoryPageContentFilter",
  data: () => ({}),
  computed: {
    sorting() {
      return this.$store.getters["category/getSorting"];
    },
    selectOptions() {
      const sortOpt = [];
      Object.keys(config.sortingOptions).forEach((element) => {
        const option = {
          value: element,
          text: this.$t(`sortingOptions.${element}`),
        };
        sortOpt.push(option);
      });
      return sortOpt;
    },
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
  },
  methods: {
    changeSorting(sortVal) {
      this.$store.commit("category/setSorting", sortVal);

      const { path } = this.$route;
      const query = { ...this.$route.query };

      const { sortDefault } = config;
      if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
        query["sort"] = sortVal;
      } else {
        delete query["sort"];
      }
      delete query["page"];

      this.$router.replace({ path, query });
    },
  },
};
</script>

<style lang="scss" scoped></style>