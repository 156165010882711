<template>
  <div>
    <div v-if="isLoading" class="box">
      <div class="loader"></div>
    </div>
    <div v-else-if="totalProducts === 0">
      <div class="no-data-found">
        <p>{{ $t("no_data_found") }}</p>
      </div>
    </div>
    <div v-else class="product-list" :class="productListClass">
      <ProductsSection :products-list="productList" :viewType="viewType" />
    </div>
  </div>
</template>

<script>
import ProductsSection from "@/base/core/components/products/category-product/ProductsSection";

export default {
  name: "FilteredProducts",
  props: ["viewType"],
  components: {
    ProductsSection,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    isLoading() {
      return this.$store.getters["category/getLoadProducts"];
    },
    productList() {
      return this.$store.getters["category/getShowProducts"];
    },
    totalProducts() {
      return this.$store.getters["category/getTotalProducts"];
    },
    productListClass() {
      if (this.viewType) {
        return this.viewType === "list" ? "list" : "grid";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/base/scss/variables.scss";

.box {
  width: 500px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 767px) {
    width: 100%;
    height: 200px;
  }
}

[class*="loader"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: inherit;
  vertical-align: middle;
  pointer-events: none;
}

.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  animation-fill-mode: both;
  animation: loader 1.8s infinite ease-in-out;
}

.loader {
  color: $primaryColor;
  position: relative;
  transform: translateZ(0);
  animation-delay: -0.16s;
  top: -1em;
  &:before {
    right: 100%;
    animation-delay: -0.32s;
  }
  &:after {
    left: 100%;
  }
  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    width: inherit;
    height: inherit;
  }
}

@keyframes loader {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.5em;
  }
  40% {
    box-shadow: 0 1.5em 0 -0.2em;
  }
}
</style>
