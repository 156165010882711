<template>
  <div class="">
    <div class="product-output-list">
      <CategoryPageContent @toggleFilter="toggleFilter" />
    </div>
  </div>
</template>

<script>
import CategoryPageContent from "@/base/core/components/products/category-product/CategoryPageContent";
// import AdvertisingBanner from "@/base/core/components/products/category-product/AdvertisingBanner";
// import BestSellers from "@/base/core/components/products/category-product/BestSellers";
export default {
  name: "ProductListingV2",
  components: {
    // BestSellers,
    CategoryPageContent,
    // AdvertisingBanner,
  },
  computed: {
    category() {
      const category = this.$store.getters["category/getCurrentCategory"];
      return category;
    },
    bestsellers() {
      return this.$store.getters["category/getBestSellersSimple"];
    },
  },
  methods: {
    toggleFilter(event) {
      this.$emit("toggleFilter", event);
    },
  },
};
</script>

<style lang="scss" scoped></style>
