<template>
  <div>
    <Breadcrumbs />

    <div class="category-list-page" v-if="category.brand == null">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 sidebar-tab-none" v-if="this.showProducts">
            <ProductFilter
              v-if="this.showProducts"
              :filterExpanded="filterExpanded"
              @toggleFilter="toggleFilter"
            />
          </div>
          <div
            :class="this.showProducts ? 'col-lg-9' : 'col-lg-12'"
            class="sidebar-full"
          >
            <AdvertisingBanner class="mb-3" />
            <div class="go-back-btn for-mobile">
              <b-link @click.native="$router.back()"
                ><b-icon icon="chevron-left"></b-icon
                >{{ $t("go_back") }}</b-link
              >
            </div>
            <div class="banner-img">
              <section
                class="short-info"
                v-if="category.description != undefined"
              >
                <v-runtime-template
                  v-if="category.description != undefined"
                  :class="['cms-block-content']"
                  :template="`<div>` + category.description + `</div>`"
                ></v-runtime-template>
              </section>
            </div>
            <ProductsListingV2
              @toggleFilter="toggleFilter"
              v-if="this.showProducts"
            />
          </div>
        </div>
        <div v-if="categoryForm != null">
          <dynamic-forms :category_id="category.id" type="category" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import ProductFilter from "@/base/core/components/products/category-product/ProductFilter";
import ProductsListingV2 from "@/base/core/components/products/category-product/ProductListingV2";
import DynamicForms from "@/base/core/components/DynamicForms";
import AdvertisingBanner from "@/base/core/components/products/category-product/AdvertisingBanner";
import VRuntimeTemplate from "v-runtime-template";

export default {
  name: "CategoryOverviews",
  components: {
    Breadcrumbs,
    ProductFilter,
    ProductsListingV2,
    DynamicForms,
    AdvertisingBanner,
    VRuntimeTemplate,
  },
  computed: {
    category() {
      return this.$store.getters["category/getCurrentCategory"];
    },
    categoryId() {
      return this.$store.getters["category/getCurrentCategoryId"];
    },
    categories() {
      return this.category.children;
    },
    categoryForm() {
      return this.$store.getters["forms/getCategoryForm"];
    },
    gtmProducts() {
      if (this.showProducts) {
        return this.$store.getters["category/getGtmProducts"];
      } else {
        return [];
      }
    },
    ga4Products() {
      if (this.showProducts) {
        return this.$store.getters["category/getGa4Products"];
      } else {
        return [];
      }
    },
    showProducts() {
      if (this.category.display_mode == "PRODUCTS") {
        return true;
      } else if (this.category.display_mode == "PAGE") {
        this.$store.commit("category/setLoadProducts", false);
        return false;
      } else if (this.category.display_mode == "PRODUCTS_AND_PAGE") {
        return true;
      } else {
        this.$store.commit("category/setLoadProducts", false);
        return false;
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        const query = {};
        const pageSize = config.products?.perPage;
        const perPage = this.$store.getters["category/getProductsPerPage"];
        if (perPage != pageSize) query["page-size"] = perPage;
        this.$router.push({ path: to.path, query: query });
      }
    },
    gtmProducts() {
      if (this.$gtm.enabled()) {
        if (!this.$gtm.uaDisabled) {
          if (this.gtmProducts.length > 0) {
            window.dataLayer.push({
              event: "eec.impressionView",
              ecommerce: {
                impressions: this.gtmProducts,
              },
            });
          }
        }
        if (this.$gtm.ga4Enabled) {
          window.dataLayer.push({
            event: "view_item_list",
            ecommerce: {
              item_list_id: "category",
              item_list_name: this.category.name,
              items: this.ga4Products,
            },
          });
        }
      }
    },
  },
  mounted() {
    this.setBreadcrumb();
  },
  destroyed() {
    Logger.debug("DESTROYED", "Category.vue", this.categoryId)();
    this.$store.commit("category/setLoadProducts", false);
  },
  methods: {
    toggleFilter(event) {
      this.filterExpanded = event;
    },
    setBreadcrumb() {
      const breadcrumbs = this.category.breadcrumbs;
      let currentName = "undefined";
      if (typeof this.category.name == "string") {
        currentName = this.category.name;
      }
      const bcrumb = { current: currentName, routes: [] };
      if (breadcrumbs != null) {
        breadcrumbs.sort((a, b) => {
          if (a.category_level < b.category_level) return -1;
          if (a.category_level > b.category_level) return 1;
          return 0;
        });
        let path = "";
        breadcrumbs.forEach((element) => {
          if (path.length > 0) {
            path = path + "/";
          }
          path = path + element.category_url_key;
          let name = "undefined";
          if (typeof element.category_name == "string") {
            name = element.category_name;
          }
          const bc = {
            name: name,
            route_link: "/" + path,
          };
          bcrumb.routes.push(bc);
        });
      }
      this.$store.commit("breadcrumbs/set", bcrumb);
    },
  },
  data() {
    return {
      filterExpanded: false,
    };
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          name: "title",
          content: this.category
            ? this.category.meta_title ?? this.category.name ?? ""
            : "",
        },
        {
          name: "keywords",
          content: this.category ? this.category.meta_keywords ?? "" : "",
        },
        {
          name: "description",
          content: this.category ? this.category.meta_description ?? "" : "",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.category
        ? this.category.meta_title ?? this.category.name ?? ""
        : "",
      // all titles will be injected into this template
    };
  },
};
</script>

<style lang="scss" scoped>
:root {
  --red: #ef233c;
  --darkred: #c00424;
  --platinum: #e5e5e5;
  --black: #2b2d42;
  --white: #fff;
  --thumb: #edf2f4;
}
</style>
