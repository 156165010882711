<template>
  <div class="filter-wrapper">
    <!-- mobile filter view -->
    <b-sidebar
      id="filter-sidebar"
      title="Filters"
      shadow
      v-if="mobileCheck"
      backdrop-variant="dark"
      backdrop
    >
      <template #footer="{ hide }">
        <div
          class="
            d-flex
            align-items-center
            padding:
            env(safe-area-inset-top)
            env(safe-area-inset-right)
            env(safe-area-inset-bottom)
            env(safe-area-inset-left)
          "
        >
          <b-button
            class="d-lg-none"
            style="width: 100%; margin: 10px"
            variant="success"
            @click="hide"
            >Toon {{ totalProducts }} producten</b-button
          >
        </div>
      </template>
      <div class="px-3 py-2">
        <div class="filter-collapse" :class="{ show: filterExpanded }">
          <ProductFilterContent @toggleFilter="toggleFilter" />
        </div>
      </div>
    </b-sidebar>

    <!-- Is not mobile / desktop filter view -->
    <div v-else-if="!mobileCheck" class="filter fancy-scroll">
      <div
        class="fake-overlay"
        v-if="overlayIsReady"
        :class="{ show: filterExpanded }"
      ></div>
      <transition name="slide-fade">
        <div class="filter-collapse" :class="{ show: filterExpanded }">
          <ProductFilterContent @clicked="toggleFilter" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import ProductFilterContent from "@/base/core/components/products/category-product/ProductFilterContent";
import { isServer } from "@storefront/core/helpers";
import { isTablet, isMobileOnly } from "mobile-device-detect";

export default {
  name: "ProductFilter",
  components: {
    ProductFilterContent,
  },
  props: {
    dontShowActiveFilters: {
      type: Boolean,
    },
    filterExpanded: {
      type: Boolean,
    },
  },

  created() {
    if (!isServer) {
      window.addEventListener("resize", this.resize);
    }
  },
  computed: {
    mobileCheck() {
      if (isMobileOnly || isTablet) {
        return true;
      } else {
        return false;
      }
    },
    totalProducts() {
      return this.$store.getters["category/getTotalProducts"];
    },
  },
  mounted() {
    this.overlayIsReady = true;
    /* $('#sidebar-1').css(
        {'top': '60px',
          'opacity': '80%'
        }
    );*/
  },
  destroyed() {
    if (!isServer) {
      window.removeEventListener("resize", this.resize);
    }
  },
  methods: {
    resize() {
      if (window.innerWidth > 991) this.filterExpanded = false;
    },
    toggleFilter(event) {
      this.$emit("toggleFilter", event);
    },
    // toggleFilter() {
    //   this.filterExpanded = !this.filterExpanded;
    // },
    removeSelectedItem(id) {
      const index = this.filterItemsSelected.findIndex(
        (item) => item.id === id
      );
      if (index > -1) {
        this.filterItemsSelected[index].isChecked = false;
      }
    },
  },
  data() {
    return {
      overlayIsReady: false,
      // filterExpanded:false
    };
  },
};
</script>

<style lang="scss">
@media (min-width: 1200px) {
  .filter-wrapper {
    height: 100%;
  }
}
</style>
